import React from 'react';
import styled from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import { Container } from './components';

import logo from './assets/laminar-gri.svg';

const GRI = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  width: 100vw;
  height: 100%;
`;

const Logo = styled.img`
  height: 50pt;
`;

const Banner = styled.div`
  width: 3pt;
  margin-right: 2em;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
`;

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Footer = styled.div`
  font-size: 0.85em;
  padding: 2em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #888;
`;

const Copyright = styled.div`
`;

const Mail = styled.div`
  a {
    color: #888;
    text-decoration: none;
    border-bottom: 2px solid #eee;
  }
`;

const Component = () => {
  return (
    <GRI>
      <Content>
        <Logo src={logo}></Logo>
      </Content>
      <Footer>
        <Copyright>
          Copyright © Laminar / GRI 2023
        </Copyright>
        <Mail>
          <a href="mailto:hello@laminar.one">Say Hello</a>
        </Mail>
      </Footer>
    </GRI>
  )
};


export default Component;
