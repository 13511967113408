export default {
  blue: '#0055ff',
  red: '#ff0000',
  gray: '#686868',
  border: '#f0f0f0',
  sectionBlock: '#ccc',
  sectionLine: '#eee',
  
  maxWidth: '1000pt',
  mediumWidth: '800pt',
  compactWidth: '600pt',
  mobileWidth: '500pt'
};
