import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  margin: 3rem;
  @media (max-width: ${p => p.theme.compactWidth}) {
    margin: 1.5rem;
  }
`;

export const ThemeText = styled.div`
  background: linear-gradient(90deg, rgba(0,85,255,1) 0%, rgba(255,0,0,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${p => p.reverse ? 'row-reverse' : 'row'};

  h2 {
    font-weight: 300;
    text-transform: uppercase;
    font-size: 1.5rem;
    letter-spacing: 0.25rem;
    color: ${p => p.theme.gray};

    @media (max-width: ${p => p.theme.compactWidth}) {
      font-size: 1rem;
    }

    background: linear-gradient(90deg, rgba(0,85,255,1) 0%, rgba(255,0,0,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

  }
`;

const SectionTitleBlock = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${p => p.theme.sectionLine};
  margin-right: 0.5rem;
`;

const SectionTitleLine = styled.div`
  height: 1px;
  width: 28px;
  background-color: ${p => p.theme.sectionLine};
  margin-right: 0.5rem;
  @media (max-width: ${p => p.theme.compactWidth}) {
    flex: 1;
  }
`;

export const SectionHeader = props => {
  return(
    <SectionTitle reverse={props.reverse}>
      <SectionTitleBlock />
      <SectionTitleLine />
      <h2>{props.children}</h2>
    </SectionTitle>
  )
};