import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import Intro from './intro';

import theme from './theme';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Intro />
    </ThemeProvider>
  )
};


export default App;
